<template>

  <b-card>
    <div>
      <!--      <p>Connected Users: {{ connectedUsers }}</p>-->
    </div>
    <h4 class="">
      <feather-icon
        icon="BoxIcon"
        style="width: 18px; height: 18px"
      />
      실시간 접속 회원
      <b-badge
        variant="light-primary"
        style="font-size: small; margin-left: 5px; margin-right: 3px"
        class="badge-glow"
      >
        {{ totalRows }}건
      </b-badge>
      <b-badge
        variant="light-info"
        style="font-size: small; margin-right: 3px"
      >
        실시간
      </b-badge>
    </h4>

    <div class="m-2">
      <!-- Per Page -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <b-form-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            size="sm"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <!--                <div class="d-flex justify-content-end">-->
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- Table -->
    <b-table
      responsive
      show-empty
      hover
      small
      empty-text="No matching records found"
      :per-page="perPage"
      :items="connectedUsers"
      :fields="fields1"
      :filter="filter"
      :filter-included-fields="filterOn"
      style="text-align: center !important; font-size: small"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :current-page="currentPage"
      @filtered="onFiltered"
    >
      <template #cell(index)="data">
        {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
      </template>
      <!-- Column: Role -->
      <template #cell(role)="data">
        <b-media class="align-content-center">
          <template>
            <b-avatar
              size="28"
              :src="data.item.role"
              :text="avatarText(data.item.role)"
              :variant="`${resolveUserRoleVariant(data.item.role)}`"
            />
          </template>
        </b-media>
      </template>
      <template #cell(site)="data">
        <div style="min-width: 8rem">
          {{ data.item.site }}
        </div>
      </template>
      <template #cell(userid)="data">
        <div
          class="text-success font-weight-bold d-block text-nowrap"
          style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
          @click="userClicked(data)"
        >
          {{ data.item.userid }}
        </div>
        <div
          class=""
          style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"
          @click="userClicked(data)"
        >
          {{ data.item.nickname }}
        </div>
      </template>
      <!-- Column: recommandNickname -->
      <template #cell(recommendNickname)="data">
        <div
          class="text-left"
          style="color:rgb(229,166,48); min-width: 5rem"
        >
          {{ data.item.recommendNickname }}
        </div>
      </template>
      <template #cell(connectSite)="data">
        <div
          class="text-info"
          style=" min-width: 5rem"
        >
          {{ data.item.connectSite }}
        </div>
      </template>
      <template #cell(menu)="data">
        <div class="text-info" style="min-width: 5rem">
          {{ data.item.menu }}
        </div>
      </template>
      <template #cell(menuCategory)="data">
        <div class="text-info" style="min-width: 7rem">
          {{ data.item.menuCategory }}
        </div>
      </template>
<!--      <template #cell(username)="data">-->
<!--        <div style="min-width: 8rem">-->
<!--          {{ data.item.username }}-->
<!--        </div>-->
<!--      </template>-->
      <template #cell(mobile)="data">
        <div style="min-width: 10rem">
          {{ data.item.mobile }}
        </div>
      </template>
      <template #cell(rank)="data">
        <div style="min-width: 2rem">
          {{ data.item.rank }}
        </div>
      </template>
      <template #cell(createdAt)="data">
        <div
          class="text-info"
          style="min-width: 12rem"
        >
          {{ data.item.createdAt }}
        </div>
      </template>
      <template #cell(duration)="data">
        <div
          class="text-info"
          style="min-width: 4rem"
        >
          {{ getElapsedTime(data.item.createdAt) }}
        </div>
      </template>
      <!-- Column: cash -->
      <template #cell(cash)="data">
        <div
          class="text-right"
          style="color:rgb(204,204,29)"
        >
          {{ Number(data.item.cash).toLocaleString() }}
        </div>
      </template>
      <!-- Column: point -->
      <template #cell(point)="data">
        <div
          class="text-right"
          style="color:rgb(204,204,29); min-width: 3rem"
        >
          {{ Number(data.item.point).toLocaleString() }}
        </div>
      </template>
      <template #cell(enrollDate)="data">
        <div
          class="font-weight-bold d-block text-nowrap"
          style="text-align: center !important"
        >
          {{ data.item.enrollDate | formatDateSmpl }}
        </div>
      </template>
      <template #cell(enrollIp)="data">
        <div style="min-width: 7rem">
          <div
            v-b-tooltip.hover.bottom.v-danger="data.item.enrollIp"
            class="font-weight-bold d-block text-nowrap"
            style="text-align: center !important"
          >
            {{ truncateText(data.item.enrollIp, 15) }}
          </div>
          <div
            v-b-tooltip.hover.bottom.v-danger="data.item.connectIp"
            class="text-info"
            style="text-align: center !important"
          >
            {{ truncateText(data.item.connectIp, 15) }}
          </div>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BAvatar,
  BMedia,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BAvatar,
    BMedia,
    avatarText,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      connectedUsers: [],
      socket: null,
      perPage: 10,
      pageOptions: [10, 20, 50],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      fields1: [
        { key: 'index', sortable: false, label: 'No.' },
        // { key: 'site', sortable: false, label: '소속사이트' },
        { key: 'connectSite', sortable: false, label: '접속사이트' },
        { key: 'role', sortable: false, label: '권한' },
        { key: 'userid', sortable: false, label: '아이디' },
        { key: 'recommendNickname', sortable: false, label: '상위' },
        { key: 'createdAt', sortable: false, label: '접속일시' },
        { key: 'duration', sortable: false, label: '접속경과' },
        { key: 'menu', sortable: false, label: '접속위치' },
        { key: 'menuCategory', sortable: false, label: '위치상세' },
        { key: 'mobile', sortable: false, label: '전화번호' },
        { key: 'rank', sortable: false, label: '등급' },
        { key: 'cash', sortable: false, label: '캐쉬' },
        { key: 'point', sortable: false, label: '포인트' },
        { key: 'enrollDate', sortable: false, label: '가입일' },
        { key: 'enrollIp', sortable: false, label: '가입IP/접속IP' },
        // { key: 'id', sortable: false, label: 'UID' },
      ],
      elapsedTime: '',
    }
  },
  created() {
    this.$socket.on('connectedUsers', data => {
      console.log('Connected Users: ', data)
      this.connectedUsers = data
    })
  },
  mounted() {
    this.$socket.emit('getConnectedUsers', {
      userid: this.userData.userid,
    }, data => {
      console.log('getConnectedUsers: ', data)
      this.connectedUsers = data
    })
    setInterval(() => {
      this.$forceUpdate() // Vue 강제 갱신
    }, 1000)
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
      // console.log(record.userid)
      // router.replace(`/apps/users/view/${record.userid}`)
      // localStorage.setItem('userView', JSON.stringify({ userid: record.userid, site: record.site }))
      // router.push({ name: 'apps-user-view' })
    },
    userClicked(data) {
      localStorage.setItem('userView', JSON.stringify({ userid: data.item.userid, site: data.item.site, nickname: data.item.nickname }))
      const url = router.resolve({ name: 'apps-user-view' }).href
      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    getElapsedTime(createdAt) {
      const startTime = new Date(createdAt) // "2024.08.24 20:41:53" 형식의 문자열을 Date 객체로 변환
      const now = new Date()
      const diff = now - startTime
      const hours = Math.floor(diff / 1000 / 60 / 60)
      const minutes = Math.floor((diff / 1000 / 60) % 60)
      const seconds = Math.floor((diff / 1000) % 60)
      return `+${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
